body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'Playfair Display', serif;
}

code, p, h2, button, #content {
  font-family: 'Poppins', sans-serif;
}
