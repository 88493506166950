@import "./variables.scss";

.app {
  // display: flex;
}

h1,
h2 {
  color: $white;
}

h1 {

  font-size: 4em;
  margin: 2em 0 .5em;

  @media (max-width: $mq-max-width) {
    font-size: 3em;
  }
}

h2 {
  text-align: center;
}

*:focus {
  outline-color: $secondary;
}

body {
  background-color: $primary;
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  overflow-y: auto;

  @media (max-width: $mq-max-width) {
    margin: 0 1em;
  }
}

.no-results,
#content {
  color: $white;
  margin-top: 0;
}

.hero {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 500px;
  background-image: url("./assets/images/rex.WebP");
  background-size: cover;
  background-position: center;
  position: fixed;
  z-index: -1000;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($primary, .4);
  }
}

label {
  color: $white;
  font-weight: bold;
}

header {
  width: 100%;
  // background-color: $black;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  justify-content: flex-end;

  button {
    margin: .5em;
    padding: .5em 1em;
    
    background-color: transparent;
    color: $white;
    font-weight: bold;
    border: none;
    border-radius: 4px;

    &:disabled {
      color: $gray;
    }

    &:hover:not(:disabled) {
      // background-color: mix($secondary, black, 90%);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}


.search-container {
  position: relative;
  display: flex;
  align-items: center;
}

input[type="text"] {
  width: 40vw;
  /* Adjust the width as needed */
  height: 50px;
  /* Adjust the height as needed */
  border-radius: 100px;
  border: none;
  padding: 0 30px 0 10px;
  border: 2px solid white;
  background-color: rgba($black, .3);
  color: $white;
  font-weight: bold;
  /* Adjust the padding to make room for the icon */

  &::placeholder {
    color: $gray;
  }

  @media (max-width: $mq-max-width) {
    width: 80vw;
  }
}

.search-icon {
  position: absolute;
  right: 10px;
  /* Adjust the distance from the right */
  cursor: pointer;
}

.rank-container {
  display: flex;
  flex-direction: column;
  margin: 1em 0 0;

  .star-radios {
    display: flex;
    justify-content: center;
    margin-bottom: .5em;
  }

  .circle-radios {
    display: flex;
  }
}

/* Optional: Style the SVG icon */
.search-icon svg {
  width: 16px;
  /* Adjust the icon size */
  height: 16px;
  fill: #333;
  /* Adjust the icon color */
}

// Style the radio input
.circle-radio {
  display: flex;
  align-items: center;

  input[type="radio"] {
    width: 1em;
    height: 1em;

    @media (max-width: $mq-max-width) {
      width: 1em;
      height: 1em;
    }
  }


}



.star-radio {
  display: none;
  /* Hide the actual radio button */
}

.star-label {
  display: inline-block;
  width: 2em;
  height: 2em;
  margin: .1em;
  /* Adjust the height as needed */
  background-image: url('./assets/images/emptyStar3D.WebP');
  /* Replace with the path to your star icon */
  background-size: contain;
  cursor: pointer;
  background-repeat: no-repeat;
}


.star-label.selected,
.hovered {
  background-image: url('./assets/images/fullStar.WebP');
  /* Replace with the path to your star icon */
}

.selected {
  &.unhovered {
    opacity: .7;
  }
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}
@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  100% {
    transform: rotate(1800deg);
  }
}
