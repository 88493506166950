.stars{
    display: flex;
    align-items: center;
    margin: 1em;
    justify-content: space-around;
    .star {
        height: 3em;
        width: 3em;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin: .1em;

    
        &.full {
            background-image: url("../../assets/images/fullStar.WebP")
        }
    
        &.empty {
            background-image: url("../../assets/images/emptyStar3D.WebP")
        }
    
        &.half {
            background-image: url("../../assets/images/halfStar.WebP")
        }
}
}
