// Common variables
$primary: rgb(8, 3, 27);
$secondary: rgb(255, 196, 0);

// Media query variables
$mq-max-width: 768px;

// Variables with media query adjustments
$white: mix($primary, white, 10%);
$black: mix($primary, black, 10%);
$gray: mix($primary, lightgray, 10%);