@import "./../../variables.scss";

.card {
    // border: 1px solid black;
    width: 50vw;
    // height: 300px;
    margin: 1em 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: $white;
    color: $black;

    @media (max-width: $mq-max-width) {
        width: 90vw;
    }

}

.card:not(.inactive) {
    -webkit-box-shadow: 10px 6px 24px -19px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 10px 6px 24px -19px rgba(0, 0, 0, 0.75);
    box-shadow: 10px 6px 24px -19px rgba(0, 0, 0, 0.75);
}

h3 {
    margin-bottom: .0em;
    font-size: 2em;
}
.inactive {
    opacity: .8;
    color: $gray;
}

.notes {
    max-width: 90%;
}

.type {
    color: $gray;
}

.img {
    height: 5em;
    width: 5em;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    // margin: 1em;

    &.good {
        background-image: url("../../assets/images/good.WebP")
    }

    &.bad {
        background-image: url("../../assets/images/bad.WebP")
    }

    &.closed {
        background-image: url("../../assets/images/closed.WebP")
    }

    &.question {
        background-image: url("../../assets/images/question.WebP")
    }
}